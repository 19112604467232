let host = document.location.host;
// let apiUrl = 'http://portal.jcsoftwaresolution.in:6056/'
let apiUrl = 'https://api.qrcode-bubble.com/'
// let apiUrl = 'https://api.qr-code.com/'

if (host.includes('localhost')) {
  // apiUrl = 'http://portal.jcsoftwaresolution.in:6056/'
  apiUrl = 'https://api.qrcode-bubble.com/'
  // apiUrl = 'https://api.qr-code.com/'
}else if (host.includes('qrcode.jcsoftwaresolution.in')){
  apiUrl = 'https://qrapi.jcsoftwaresolution.in/'
}else{
  // apiUrl = 'http://66.179.251.9:6056/'
  apiUrl = 'https://api.qrcode-bubble.com/'
  // apiUrl = 'http://portal.jcsoftwaresolution.in:6056/'
  // apiUrl = 'https://api.qr-code.com/'
}

let liveURL = 'https://qr1.co/'

// if (host.includes('localhost')) {
//   liveURL = 'http://66.179.251.9:8056/'
// }else if (host.includes('qrcode.jcsoftwaresolution.in')){
//   liveURL = 'https://qrcode.jcsoftwaresolution.in/'
// }else{
//   liveURL = 'http://66.179.251.9:8056/'
// }

const environment = {
  api: apiUrl,
  // liveURL: `https://qrcode.jcsoftwaresolution.in/`
  stripe_price_id:"price_1P4JQ8EGnmCJcey2grIztQqq",
  liveURL:liveURL
};

export default environment;