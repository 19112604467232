import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const TemplatesDesign = ({ setform, form, setframeForm }) => {
  const [templates, settemplates] = useState([]);
  const [selectedTemplate, setselectedTemplate] = useState();

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = () => {
    ApiClient.get(`template/all`).then((res) => {
      if (res.success) {
        settemplates(res?.data?.data);
      }
    });
  };
  

  const activeTemplate = (id) => {
    setselectedTemplate(id);
    loader(true);
    ApiClient.get(`template?id=${id}`).then((res) => {
      if (res.success) {
        setform({
          ...form,
          logoimg: res?.data?.design?.logoimg,
          logosize: res?.data?.design?.logosize,
          qrOuterEye: res?.data?.design?.qrOuterEye,
          qrInnerEye: res?.data?.design?.qrInnerEye,
          qrBodyPattern: res?.data?.design?.qrBodyPattern,
          qrOuterEyeColor: res?.data?.design?.qrOuterEyeColor,
          qrInnerEyeColor: res?.data?.design?.qrInnerEyeColor,
          qrBodyPatternColor: res?.data?.design?.qrBodyPatternColor,
          backgroundColor: res?.data?.design?.backgroundColor,
          qrOuterEyeColorGradient1: res?.data?.design?.qrOuterEyeColorGradient1,
          qrOuterEyeColorGradient2: res?.data?.design?.qrOuterEyeColorGradient2,
          qrOuterEyeColorGradientType:
            res?.data?.design?.qrOuterEyeColorGradientType,
          qrInnerEyeColorGradient1: res?.data?.design?.qrInnerEyeColorGradient1,
          qrInnerEyeColorGradient2: res?.data?.design?.qrInnerEyeColorGradient2,
          qrInnerEyeColorGradientType:
            res?.data?.design?.qrInnerEyeColorGradientType,
          qrBodyPatternGradientColor1:
            res?.data?.design?.qrBodyPatternGradientColor1,
          qrBodyPatternGradientColor2:
            res?.data?.design?.qrBodyPatternGradientColor2,
          qrBodyPatternGradientColorType:
            res?.data?.design?.qrBodyPatternGradientColorType,
          backgroundGradientColor1: res?.data?.design?.backgroundGradientColor1,
          backgroundGradientColor2: res?.data?.design?.backgroundGradientColor2,
          backgroundGradientColorType:
            res?.data?.design?.backgroundGradientColorType,
          errorCorrection: res?.data?.design?.errorCorrection,
        });
        if (res?.data?.frames) setframeForm(res?.data?.frames);
      }
      loader(false);
    });
  };

  return (
    <>
      <div className="box_images">
        {templates &&
          templates.map((item, index) => {
            return (
              <img
                src={item?.image}
                className={item?.id == selectedTemplate ? "box_images2" : ""}
                onClick={(e) => activeTemplate(item?.id)}
              ></img>
            );
          })}
      </div>
    </>
  );
};

export default TemplatesDesign;
