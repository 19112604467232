import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../components/global/header";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Pagination from "react-js-pagination";
import Select from "react-select";

import Footer from "../../components/global/Footer";

const FAQ = () => {
  const [faqs, setfaqs] = useState();
  const [search, setsearch] = useState();
  const [filter, setfilter] = useState({
    page: 1,
    count: 10,
    search: "",
    category: "",
  });
  const [total, settotal] = useState(0);
  const [category, setcategory] = useState();

  useEffect(() => {
    getFAQS();
    getCategory();
  }, []);

  const getCategory = () => {
    ApiClient.get(`common-category/all?type=faq`).then((res) => {
      if (res.success) {
        setcategory(
          res.data.map((item) => {
            return { value: item?._id, label: item?.name };
          })
        );
      }
    });
  };

  const getFAQS = (p = {}) => {
    let filt = { ...filter, ...p, sortBy: "srNumber asc" };
    loader(true);
    ApiClient.get(`faq/all`, filt).then((res) => {
      if (res.success) {
        setfaqs(res.data.data);
        settotal(res.data.total);
      }
      loader(false);
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setfilter({ ...filter, search: search });
    getFAQS({ search: search });
  };

  const handlePagination = (e) => {
    setfilter({ ...filter, page: e });
    getFAQS({ page: e });
  };

  const handleFilter = (e) => {
    setfilter({ ...filter, category: e });
    getFAQS({ category: e.value || "" });
  };

  return (
    <>
      <Header />
      <section className="faq-bg">
        <div className="faq-page">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="faq-title">
                  <h2 className="">Frequently Asked Questions</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-ques">
          <div className="container-fluid">
            <div className="accordin-tab">
              <form
                className="mb-3 w-100 d-flex justify-content-between flex-wrap"
                onSubmit={handleSearch}
              >
                <div className="d-flex faq_1">
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setsearch(e.target.value)}
                    className="form-control"
                    placeholder="Search.."
                  />
                  <button type="submit" className="btn btn-primary ms-3">
                    Search
                  </button>
                </div>
                <div className="faq_cat">
                  <Select
                    value={filter?.category}
                    placeholder="All Category"
                    onChange={(e) => handleFilter(e || "")}
                    options={category}
                    isClearable={true}
                  />
                </div>
              </form>
              {faqs &&
                faqs.map((item, index) => {
                  return (
                    <>
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item w-100">
                          <h2 className="accordion-header minus-btn">
                            <button
                              className="accordion-button w-100 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#${index}`}
                              aria-expanded="true"
                              aria-controls={index}
                            >
                              <span className="first-ltr-uppercase">
                                {item?.question}
                              </span>
                            </button>
                          </h2>
                          <div
                            id={index}
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              className="accordion-body"
                              dangerouslySetInnerHTML={{ __html: item?.answer }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            {filter?.count < total ? (
              <div className="d-flex justify-content-center">
                <Pagination
                  activePage={filter?.page}
                  itemsCountPerPage={filter?.count}
                  totalItemsCount={total}
                  pageRangeDisplayed={1}
                  onChange={handlePagination}
                  hideFirstLastPages={true}
                />
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FAQ;
