import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import "./style.scss";
import { ToastContainer, toast } from "react-toastify";

const Forgotpassword = () => {
  const user = useSelector((state) => state.user);
  const [form, setForm] = useState({ email: "" });
  const history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history("/dashboard");
    }
  }, []);

  const hendleSubmit = (e) => {
    e.preventDefault();
    loader(true);
    ApiClient.post("forgot/password/users", { ...form }).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res.message);
        }, 100);
        history(`/login`);
      }
      loader(false);
    });
  };

  return (
    <>
      <div className=" m_login">
        <div className="row align-items-center mx-0">
          <div className="col-md-4 px-0 d-none">
            <div className="banner_img"></div>
          </div>
          <div className="col-md-12 mx-auto">
            <div className="login-bx bg-white">
              <img
                src="./assets/img/favicon_2.png"
                width="150px"
                className="mb-2"
              />
              <form className="centerLogin" onSubmit={hendleSubmit}>
                <div className="logo_login d-none">
                  <img src="/assets/img/logo.png" />
                </div>
                <div className="text_logo d-none">
                  <h3>
                    {" "}
                    Integrated Business Intelligence and Sustainability
                    Dashboard for Tour Operators
                  </h3>
                </div>
                <div className="text-center mb-md-4 mb-2 ">
                  <h4 className="text-center lgtext fw-bold ">
                    Reset Password
                  </h4>
                </div>
                <p className="para_forget text-start ">
                  Enter your email address associated with your QR Bubble
                  account and we’ll send you a link to reset your password
                </p>
                <div className="mb-3 text-start">
                  <div className="inputWrapper">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-left"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control  mb-0 bginput"
                      placeholder="Email"
                      value={form.email}
                      required
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="buttons">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 py-2 mb-4"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default Forgotpassword;
