import React, { useEffect, useState } from "react";
import Footer from "../../components/global/Footer";
import Header from "../../components/global/header";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const PrivacyPolicy = () => {
  const [data, setdata] = useState({ heading: "", description: "" });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    loader(true);
    ApiClient.get(`page?type=privacy_policy`).then((res) => {
      if (res.success) {
        setdata(res?.data);
      }
      loader(false);
    });
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="page_main">
          <h1 className="page_heading">{data?.heading}</h1>
          <div className="layout-page">
            <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
