import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/global/header";
import "./style.scss"
import WebScanner from "./WebScanner";
import Footer from "../../components/global/Footer";


const QrCodeScanner = () => {

  return (
    <>
      <Header />
      <div className="bgback back-gradient py-4">
        {/* section 1 */}
        <div className="text-detail my-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h4 className="fw-bold"> Free QR Code Scanner</h4>
                <h6 className="text-primary fw-normal">You can upload the image of the QR code, or use your webcam / phone to scan the QR code.</h6>
              </div>
            </div>
          </div>
        </div>
        {/* section2 */}
        <WebScanner />
      </div>
      <Footer/>
    </>
  )
}

export default QrCodeScanner