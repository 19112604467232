import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="footer_design">
        <div className="container">
          <div className="footer">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <img
                  src="/assets/img/logo_image.png"
                  className="footer_logo"
                ></img>
                <p>
                  Enhance your marketing campaigns with custom, trackable QR
                  Codes
                </p>
              </div>
              <div className="col-md-6 col-lg-4">
                <h1 className="heading_footer">QR Codes</h1>
                <div className="flx_footer">
                  <div className="ul_div">
                    <ul>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/whatsapp"
                          className="link_color"
                        >
                          WhatsApp QR Code
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/facebook"
                          className="link_color"
                        >
                          Facebook QR Code
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/youtube"
                          className="link_color"
                        >
                          Youtube QR Code
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/tiktok"
                          className="link_color"
                        >
                          TikTok QR Code
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/instagram"
                          className="link_color"
                        >
                          Instagram QR Code
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="ul_div">
                    <ul>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/app-store"
                          className="link_color"
                        >
                          App Store QR Code
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/play-store"
                          className="link_color"
                        >
                          Play Store QR Code
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/multiple-apps"
                          className="link_color"
                        >
                          Multiple Apps QR Code
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/vcard"
                          className="link_color"
                        >
                          vCard QR Code
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={(e) => scrollToTop()}
                          to="/google-forms"
                          className="link_color"
                        >
                          Google Forms QR Code
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-2">
                <h1 className="heading_footer">Product</h1>
                <ul>
                  <li>
                    <Link
                      onClick={(e) => scrollToTop()}
                      to="/qr-code-scanner"
                      className="link_color"
                    >
                      QR Code Scanner
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={(e) => scrollToTop()}
                      to="/bulk-qr-code"
                      className="link_color"
                    >
                      Bulk QR Codes
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={(e) => scrollToTop()}
                      to="/faq"
                      className="link_color"
                    >
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={(e) => scrollToTop()}
                      to="/pricing"
                      className="link_color"
                    >
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={(e) => scrollToTop()}
                      to="/blog"
                      className="link_color"
                    >
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-6 col-lg-2">
                <h1 className="heading_footer">Support</h1>
                <ul>
                  <li>    
                    <Link
                      onClick={(e) => scrollToTop()}
                      to="/contact"
                      className="link_color"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={(e) => scrollToTop()}
                      to="/terms"
                      className="link_color"
                    >
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={(e) => scrollToTop()}
                      to="/privacy"
                      className="link_color"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={(e) => scrollToTop()}
                      to="/cookie-policy"
                      className="link_color"
                    >
                      Cookie Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row_footer">
            <p>© 2023 QR BUBBLE. All rights reserved.</p>
            <p>
              © QR Code is a registered trademark of Denso Wave Incorporated.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
