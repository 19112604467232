import React, { useState } from "react";
import "./style.scss";
import Header from "../../components/global/header";
import { toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import Footer from "../../components/global/Footer";

const Contactus = () => {
  const [form, setform] = useState({
    firstName: "",
    // lastName: "",
    email: "",
    // phone: "",
    // subject: "",
    query: "",
  });

  const handleContactUsForm = (e) => {
    e.preventDefault();
    ApiClient.post(`add/contactFormData`, { ...form }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setform({
          firstName: "",
          // lastName: "",
          email: "",
          // phone: "",
          // subject: "",
          query: "",
        });
      }
    });
  };

  return (
    <>
      <Header />
      <div className="form-detail">
        <div className="container">
        <h1 className="fw-bold text-center text-sm-center text-md-left mb-0">
                Contact Us
              </h1>
              <p className="text-center text-sm-center text-md-left mb-4 mb-md-4 ct_child">
                {" "}
                Need to get in touch with us
              </p>
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-6">
              <img
                src="./assets/img/contact-us.png"
                className="contact-img img-fluid "
                alt="..."
              />
            </div>
            <div className="col-12 col-sm-12 col-lg-6">
              <form
                onSubmit={handleContactUsForm}
                className="contact-form"
              >
                <div className="row">
                  <div className="col-md-6 mt-md-0 mb-3">
                    <label for="validationCustom01" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      value={form?.firstName}
                      onChange={(e) =>
                        setform({ ...form, firstName: e.target.value })
                      }
                      className="form-control"
                      id="validationCustom01"
                      required
                      placeholder="First Name"
                    />
                  </div>
                  {/* <div className="col-md-6 mt-md-0 mt-3">
                    <label for="validationCustom01" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={form?.lastName}
                      onChange={(e) =>
                        setform({ ...form, lastName: e.target.value })
                      }
                      className="form-control"
                      id="validationCustom01"
                      required
                      placeholder="Last Name"
                    />
                  </div> */}
                  <div className="col-md-6 mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      value={form?.email}
                      onChange={(e) =>
                        setform({ ...form, email: e.target.value })
                      }
                      className="form-control"
                      id="exampleFormControlInput1"
                      required
                      placeholder="Email"
                    />
                  </div>
                  {/* <div className="col-md-6 mt-3">
                    <label for="validationCustom01" className="form-label">
                      Contact Number
                    </label>
                    <input
                      type="number"
                      value={form?.phone}
                      minLength="10"
                      maxLength="10"
                      onChange={(e) =>
                        setform({ ...form, phone: methodModel.isNumber(e) })
                      }
                      className="form-control"
                      id="validationCustom01"
                      required
                      placeholder="Contact Number"
                    />
                  </div> */}
                </div>
                <div className="row">
                  {/* <div className="col-12">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      value={form?.subject}
                      onChange={(e) =>
                        setform({ ...form, subject: e.target.value })
                      }
                      minLength="2"
                      maxLength="30"
                      className="form-control"
                      id="exampleFormControlInput1"
                      required
                      placeholder="Subject"
                    />
                  </div> */}
                  <div className="col-12 mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label"
                    >
                      Description
                    </label>
                    <textarea
                      value={form?.query}
                      onChange={(e) =>
                        setform({ ...form, query: e.target.value })
                      }
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      required
                      placeholder="Description"
                      rows="3"
                    ></textarea>
                  </div>
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-primary mt-3">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Contactus;
