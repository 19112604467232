import React, { Suspense, lazy } from "react";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import configureStore from "./config";
// import { createBrowserHistory } from "history";
import { Navigate } from "react-router-dom";
import Login from './pages/Login'
import SignUp from './pages/Signup'
import Forgotpassword from './pages/Forgotpassword'
import Resetpassword from './pages/Resetpassword'
import FAQ from './pages/FAQ'
import Contactus from './pages/ContactUs'
import BulkQrCode from './pages/BulkQrCode'
import Plans from './pages/Plans'
import TermsOfUse from './pages/TermsOfUse'
import PrivacyPolicy from './pages/PrivacyPolicy'
import CookiePolicy from './pages/CookiePolicy'
import QrCodeScanner from './pages/QrCodeScanner'
import QrCodeListing from "./pages/QrCode/QrCodeListing"
const AccountSetting = lazy(() => import("./pages/AccountSetting"));
const Blogs = lazy(() => import("./pages/Blog/Blogs"));
const BlogDetail = lazy(() => import("./pages/Blog/BlogDetail"));
const FolderListing = lazy(() => import("./pages/Folder/FolderListing"));
const QrCodes = lazy(() => import("./pages/QrCode/QrCodes"));
const QrScanner = lazy(() => import("./pages/QrCodeScanner/QrScanner"));
const Trash = lazy(() => import("./pages/Trash"));
const AddFolder = lazy(() => import("./pages/Folder/AddFolder"));
const EditFolder = lazy(() => import("./pages/Folder/EditFolder"));
const Templates = lazy(() => import("./pages/Templates"));
const AddEditTemplate = lazy(() => import("./pages/Templates/AddEditTemplate"));
const EditQrCodes = lazy(() => import("./pages/QrCode/EditQrCodes"));
const UserBulkQrCode = lazy(() => import("./pages/BulkQrCode/UserBulkQrCode"));
const Cards = lazy(() => import("./pages/Card"));
const Analytics = lazy(() => import("./pages/Analytics"));
const ReferralAffiliate = lazy(() => import("./pages/ReferralAffiliate"));
const VcardLandingPage = lazy(() => import("./pages/VcardLandingPage"));
const HomeLoader = lazy(() => import("./pages/Logo/HomeLoader"));
const FolderLisitng = lazy(() => import("./pages/Folder/FolderListing"));
const Logo = lazy(() => import('./pages/Logo/Logo'));
import "./scss/main.scss";
// export const history = createBrowserHistory();
// const { store } = configureStore(history);

export default () => {
  return (
    <>
      <Suspense fallback={<div className="loaderDiv">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>}>
        <Router>

          <Routes>
            <Route
              exact={true}
              // store={store}
              path="/login"
              element={<Login />}
            />
            <Route
              exact={true}
              // store={store}
              path="/signup"
              element={<SignUp />}
            />
            <Route
              exact={true}
              // store={store}
              path="/forgotpassword"
              element={<Forgotpassword />}
            />
            <Route
              exact={true}
              // store={store}
              path="/resetpassword"
              element={<Resetpassword />}
            />
            {/* FAQ */}
            <Route exact={true}
              // store={store} 
              path="/faq" element={<FAQ />} />

            {/* Contact Us */}
            <Route
              exact={true}
              // store={store}
              path="/contact"
              element={<Contactus />}
            />
            {/* Qr Code Scanner */}
            <Route
              exact={true}
              // store={store}
              path="/qr-code-scanner"
              element={<QrCodeScanner />}
            />

            {/* Bulk Qr Code */}
            <Route
              exact={true}
              // store={store}
              path="/bulk-qr-code"
              element={<BulkQrCode />}
            />

            {/* Terms Of Use */}
            <Route
              exact={true}
              // store={store}
              path="/terms"
              element={<TermsOfUse />}
            />

            {/* Privacy Policy */}
            <Route
              exact={true}
              // store={store}
              path="/privacy"
              element={<PrivacyPolicy />}
            />

            {/* Cookie Policy */}
            <Route
              exact={true}
              // store={store}
              path="/cookie-policy"
              element={<CookiePolicy />}
            />

            <Route
              exact={true}
              // store={store}
              path="/app/qrcodelisting"
              element={<QrCodeListing />}
            />

            {/* Plans */}
            <Route
              exact={true}
              // store={store}
              path="/pricing"
              element={<Plans />}
            />




            {/* Account Setting */}
            <Route
              exact={true}
              // store={store}
              path="/app/accountsetting"
              element={<AccountSetting />}
            />

            {/* Blog */}
            <Route
              exact={true}
              // store={store}
              path="/blog"
              element={<Blogs />}
            />
            <Route
              exact={true}
              // store={store}
              path="/blog/detail/:id"
              element={<BlogDetail />}
            />



            {/* Card */}
            <Route
              exact={true}
              // store={store}
              path="/cards/:id/:stripeId"
              element={<Cards />}
            />

            {/* Folder */}
            <Route
              exact={true}
              // store={store}
              path="/app/folder"
              element={<FolderLisitng />}
            />
            <Route
              exact={true}
              // store={store}
              path="/app/folder/add"
              element={<AddFolder />}
            />
            <Route
              exact={true}
              // store={store}
              path="/app/folder/edit/:id"
              element={<EditFolder />}
            />


            <Route
              exact={true}
              // store={store}
              path="/app/bulkqr"
              element={<UserBulkQrCode />}
            />


            <Route
              exact={true}
              // store={store}
              path="/app/qrscanner"
              element={<QrScanner />}
            />

            {/* Qr Codes with Types */}
            <Route
              exact={true}
              // store={store}
              path="/app/qrcodes"
              element={<QrCodes />}
            />
            <Route
              exact={true}
              // store={store}
              path="/app/qrcodes/:slug"
              element={<QrCodes />}
            />
            <Route
              exact={true}
              // store={store}
              path="/app/qrcodes/:slug/:qrCodeSlug"
              element={<QrCodes />}
            />

            <Route
              exact={true}
              // store={store}
              path="/app/qrcode/edit/:slug"
              element={<EditQrCodes />}
            />

            {/* Trash Qr Codes */}
            <Route
              exact={true}
              // store={store}
              path="/app/trash"
              element={<Trash />}
            />

            {/* Templates */}
            <Route
              exact={true}
              // store={store}
              path="/app/template"
              element={<Templates />}
            />
            <Route
              exact={true}
              // store={store}
              path="/app/template/add"
              element={<AddEditTemplate />}
            />
            <Route
              exact={true}
              // store={store}
              path="/app/template/edit/:id"
              element={<AddEditTemplate />}
            />

            {/* Analytics */}
            <Route
              exact={true}
              // store={store}
              path="/app/analytics"
              element={<Analytics />}
            />



            {/* Referral & Affiliate */}
            <Route
              exact={true}
              // store={store}
              path="/app/referral"
              element={<ReferralAffiliate />}
            />

            {/* Testing URL */}
            {/* <Route
                  exact={true}
                  // store={store}
                  path="/:slug"
                  element={<Login />}{Testing}
                /> */}
            {/* Testing URL FOR VCARD */}
            <Route
              exact={true}
              // store={store}
              path="/vcard/:slug"
              element={<VcardLandingPage />}
            />

            {/* Logo */}
            <Route exact={true}
              // store={store} 
              path="/"
              element={<Logo />} />

            <Route
              exact={true}
              // store={store}
              path="/:slug"
              element={<Logo />}
            />
            <Route exact path="/" element={<Navigate to="/login" />}>
              {/* <Navigate to="/login" /> */}
            </Route>
          </Routes>
        </Router>
      </Suspense>
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastsContainer
        position={ToastsContainerPosition.TOP_RIGHT}
        store={ToastsStore}
      />
    </>
  );
};
