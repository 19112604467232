/******** Reducers ********/

const initialState = {
    planActive: false,
    notifications: [],
  };
  
  export default function reducer(state = initialState, action) {
    switch (action.type) {
      case 'plan_active':
        return { ...state, ...{ planActive: true }, ...action.data };
      case 'plan_deactive':
        return initialState;
      default:
        return state;
    }
  }
  