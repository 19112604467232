import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deactive_Plan } from "../../actions/activePlan";
import Footer from "../../components/global/Footer";
import Header from "../../components/global/header";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const Plans = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [plans, setplans] = useState([]);
  const [bill, setbill] = useState(1);
  const [faqs, setfaqs] = useState([]);
  const [activePlan, setactivePlan] = useState();
  const navigate = useNavigate();
  const urlPath = window.location.pathname;
  const token = localStorage.getItem("token");

  useEffect(() => {
    getAllPlans();
    getPaymentFaqs();
    getPurchasePlan();
    localStorage.removeItem("AfterLogin");
  }, []);

  const getPurchasePlan = () => {
    if (token) {
      loader(true);
      ApiClient.get(`active-subscription?user_id=${user?.id}`).then((res) => {
        if (res.success) {
          setactivePlan(res?.data);
        } else {
          setactivePlan("");
        }
        loader(false);
      });
    }
  };

  const getAllPlans = () => {
    ApiClient.get(`subscription-plan/all?status=active`).then((res) => {
      if (res.success) {
        setplans(res?.data);
      }
    });
  };

  const planPrice = (item) => {
    let amount = 0;
    item?.pricing.map((itm) => {
      if (itm?.interval_count == bill) {
        amount = itm?.unit_amount;
      }
    });
    if (bill == 12) {
      amount /= 12;
    }
    return parseFloat(amount).toFixed(2);
  };

  const getPaymentFaqs = () => {
    ApiClient.get(
      `faq/all?page=1&count=10&category=655df445450dd12d5db8e820&sortBy=srNumber asc`
    ).then((res) => {
      if (res.success) {
        setfaqs(res?.data?.data);
      }
    });
  };

  const buyPlan = (item) => {
    let stripeId = "";
    item?.pricing.map((item) => {
      if (item?.interval_count == bill) {
        stripeId = item?.stripe_price_id;
      }
    });
    if (user?.id) {
      navigate(`/cards/${item?._id}/${stripeId}`);
    } else {
      item?.pricing.map((item) => {
        if (item?.interval_count == "14" && item?.interval == "day") {
          stripeId = item?.stripe_price_id;
        }
      });
      localStorage.setItem("AfterLogin", urlPath);
      localStorage.setItem("stripeId", stripeId);
      localStorage.setItem("planId", item?._id);
      navigate(`/signup`);
    }
  };

  const cancelPlan = (item) => {
    loader(true);
    ApiClient.put(`cancel-subscription`, {
      subscription_plan_id: activePlan?.subscription_plan_id,
      user_id: user?.id,
    }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        dispatch(deactive_Plan());
        getAllPlans();
        getPurchasePlan();
      }
      loader(false);
    });
  };

  const planActive = (item) => {
    let value = false;
    if (
      (item?._id == activePlan?.subscription_plan_id &&
        activePlan?.interval_count == bill) ||
      (item?._id == activePlan?.subscription_plan_id &&
        activePlan?.interval_count == "14")
    ) {
      value = true;
    }
    return value;
  };

  const borderCheck = (item) => {
    let value = false;
    if (
      (planActive(item) && activePlan?.interval_count == bill) ||
      (planActive(item) && activePlan?.interval_count == "14")
    ) {
      value = true;
    } else if (item?.name == "premium" && activePlan?.interval_count != bill) {
      value = "check";
    } else {
      false;
    }
    return value;
  };

  return (
    <>
      <Header />
      <div className="main_plans">
        <div className="container">
          <div className="pricing">
            <h1 className="pricing_heading">Pricing</h1>
            <p className="text_pHeading">Trusted by top brands globally</p>
            <div className="pricing_images">
              <img src="/assets/img/pricing_1.png"></img>
              <img src="/assets/img/pricing_2.png"></img>
              <img src="/assets/img/pricing_3.png"></img>
              <img src="/assets/img/pricing_4.png"></img>
              <img src="/assets/img/pricing_5.png"></img>
              <img src="/assets/img/pricing_6.png"></img>
            </div>
            <div className="tabs_plan">
              <ul
                className="nav nav-pills plans_btn mb-3"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    onClick={(e) => setbill(1)}
                    className={`nav-link ${bill == 1 ? "active" : ""}`}
                  >
                    Bill Monthly
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    onClick={(e) => setbill(12)}
                    className={`nav-link ${bill == 12 ? "active" : ""}`}
                  >
                    Bill Yearly
                  </button>
                </li>
              </ul>
              <div className="rate_img">
                <h3>You save 30%</h3>
                <img src="/assets/img/Arrow.png"></img>
              </div>
            </div>
            <div className="tab-pane fade show active">
              <div className="pricing_table ">
                <div className="table-responsive table_data">
                  <table className="w-100">
                    <tr>
                      <th className="th_main"></th>
                      {plans &&
                        plans.map((item, index) => {
                          return (
                            <th
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-top border-primary list_popular rounded-top"
                                  : ""
                              }`}
                            >
                              {borderCheck(item) == "check" ? (
                                <p className="topular_text">Most Popular</p>
                              ) : null}
                              {borderCheck(item) == true && activePlan?.valid_upto ? (
                                <p className="topular_text">
                                  Valid Upto:{" "}
                                  {moment(activePlan?.valid_upto).format(
                                    "MMM DD, YYYY"
                                  )}
                                </p>
                              ) : null}
                              <h1 className={`text-capitalize mt-3`}>
                                {item?.name}
                              </h1>
                              <h3>
                                ${planPrice(item)}{" "}
                                <span className="month_text">
                                  / {bill == 1 ? "month" : "month"}
                                </span>
                              </h3>
                              <div className="btn_center">
                                {planActive(item) ? (
                                  <button
                                    className="btn btn-primary light_greenBtn"
                                    onClick={(e) => cancelPlan(item)}
                                  >
                                    Cancel Plan
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    onClick={(e) => buyPlan(item)}
                                  >
                                    {activePlan?.interval_count
                                      ? "Upgrade Plan"
                                      : "START FREE TRIAL"}
                                  </button>
                                )}
                              </div>
                            </th>
                          );
                        })}
                    </tr>
                    <tr>
                      <td className="fs-5">FEATURES</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`text-capitalize ${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            ></td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Static QR Codes</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`text-capitalize ${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.static_qr_codes}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Dynamic QR Codes</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.dynamic_qr_code}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Scans per Month</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`text-capitalize ${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.scans_per_month}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Tracking Analytics</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`text-capitalize ${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.tracking_analytics}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Advertisement</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`text-capitalize ${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.advertisement}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Branding</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`text-capitalize ${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.branding || "--"}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Affiliate Commission</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.affiliate_commission}%
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Bulk Upload</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.bulk_upload ? (
                                <span className="material-icons text-success">
                                  done
                                </span>
                              ) : (
                                <span className="material-icons text-danger">
                                  close
                                </span>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Full Design Options</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.full_design_option ? (
                                <span className="material-icons text-success">
                                  done
                                </span>
                              ) : (
                                <span className="material-icons text-danger">
                                  close
                                </span>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Save Design as Template</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.save_design_as_template ? (
                                <span className="material-icons text-success">
                                  done
                                </span>
                              ) : (
                                <span className="material-icons text-danger">
                                  close
                                </span>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>QR Code Scanner</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.qr_code_scanner ? (
                                <span className="material-icons text-success">
                                  done
                                </span>
                              ) : (
                                <span className="material-icons text-danger">
                                  close
                                </span>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Excel Download of Analytics</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.excel_download ? (
                                <span className="material-icons text-success">
                                  done
                                </span>
                              ) : (
                                <span className="material-icons text-danger">
                                  close
                                </span>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Max File Size</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.max_file_size}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Customer Support</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`text-capitalize ${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.customer_support || "--"}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Max Folders</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`text-capitalize ${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.max_folders || "--"}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Set Password Protection of QR Codes</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.set_password_protection ? (
                                <span className="material-icons text-success">
                                  done
                                </span>
                              ) : (
                                <span className="material-icons text-danger">
                                  close
                                </span>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Set Expiry of QR Codes by Time</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.set_expiry_of_qr_codes_by_time ? (
                                <span className="material-icons text-success">
                                  done
                                </span>
                              ) : (
                                <span className="material-icons text-danger">
                                  close
                                </span>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Set Expiry of QR Codes by Scans</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-primary"
                                  : ""
                              }`}
                            >
                              {item?.set_expiry_of_qr_codes_by_scans ? (
                                <span className="material-icons text-success">
                                  done
                                </span>
                              ) : (
                                <span className="material-icons text-danger">
                                  close
                                </span>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <td>Set GPS Tracking of QR Codes</td>
                      {plans &&
                        plans.map((item) => {
                          return (
                            <td
                              className={`${
                                borderCheck(item) == true ||
                                borderCheck(item) == "check"
                                  ? "border-left-right border-bottom border-primary"
                                  : ""
                              }`}
                            >
                              {item?.set_GPS_tracking_of_qr_codes ? (
                                <span className="material-icons text-success">
                                  done
                                </span>
                              ) : (
                                <span className="material-icons text-danger">
                                  close
                                </span>
                              )}
                            </td>
                          );
                        })}
                    </tr>
                  </table>
                </div>
              </div>
              <div className="faq_div">
                <div className="accordion">
                  <h1>Frequency Asked Questions (FAQ)</h1>
                  {faqs &&
                    faqs.map((item, index) => {
                      return (
                        <div className="accordion-item div_faqrow">
                          <input type="checkbox" id={`accordion${index}`} />
                          <label
                            for={`accordion${index}`}
                            className="accordion-item-title"
                          >
                            <span className="icon"></span>
                            <span className="first-ltr-uppercase">
                              {item?.question}
                            </span>
                          </label>
                          <div
                            className="accordion-item-desc"
                            dangerouslySetInnerHTML={{ __html: item?.answer }}
                          ></div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Plans;
